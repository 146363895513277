import { Carousel } from 'components/common/Carousel';
import { HomeCuration } from 'components/common/HomeCuration';
import React from 'react';
import { StyleObject } from 'types/styles';
import { MeetupWithWishes } from 'types/interface';
import { Flex } from 'rebass';
import { MainMembershipIntroduction } from 'components/Main/MainMembershipIntroduction';
import { MainHostOpenCall } from 'components/Main/MainHostOpenCall';
import { HorizontalCardSection } from 'components/common/HorizontalCardSection';
import { ComingSoonCardSection } from 'components/common/ComingSoonCardSection';

export interface HomeMeetupListSection {
  items: MeetupWithWishes[];
  title: string;
  name: string;
  destinationUrl?: string;
}

export interface HomeBanner {
  title: string;
  image: string;
  desktopImage?: string;
  description: string;
  destinationUrl: string;
  opacity?: string;
  target: string;
}

export interface MainProps {
  gatherings?: MeetupWithWishes[];
  events?: MeetupWithWishes[];
  banners: HomeBanner[];
  reopeningHostSalonMeetups: HomeMeetupListSection;
  upcomingSalonMeetups: HomeMeetupListSection;
  imminentSalonMeetups: HomeMeetupListSection;
  comingSoonSalonMeetups: HomeMeetupListSection;
  soldOutSalonMeetups: HomeMeetupListSection;
  curatedMeetups1: HomeMeetupListSection;
  curatedMeetups2: HomeMeetupListSection;
  curatedMeetups3: HomeMeetupListSection;
  curatedMeetups4: HomeMeetupListSection;
  curatedMeetups5: HomeMeetupListSection;
  curatedMeetups6: HomeMeetupListSection;
  curatedMeetups7: HomeMeetupListSection;
  curatedMeetups8: HomeMeetupListSection;
  curatedMeetups9: HomeMeetupListSection;
}

export const Main = ({
  reopeningHostSalonMeetups,
  upcomingSalonMeetups,
  imminentSalonMeetups,
  comingSoonSalonMeetups,
  curatedMeetups1,
  curatedMeetups2,
  curatedMeetups3,
  curatedMeetups4,
  curatedMeetups5,
  curatedMeetups6,
  curatedMeetups7,
  curatedMeetups8,
  curatedMeetups9,
  soldOutSalonMeetups,
  gatherings,
  events,
  banners,
}: MainProps) => (
  <Flex sx={styles.container}>
    <Carousel data={banners} />
    {comingSoonSalonMeetups?.items.length >= 4 && (
      <ComingSoonCardSection data={comingSoonSalonMeetups} />
    )}
    {events && events.length > 0 && (
      <HorizontalCardSection
        data={{
          destinationUrl: '/events',
          items: events.slice(0, 20),
          name: 'events',
          title: '주목할만한 이벤트',
        }}
      />
    )}
    {reopeningHostSalonMeetups.items.length >= 4 && (
      <HomeCuration data={reopeningHostSalonMeetups} />
    )}
    {upcomingSalonMeetups.items.length >= 4 && <HomeCuration data={upcomingSalonMeetups} />}
    <MainMembershipIntroduction />
    <HomeCuration data={curatedMeetups8} />
    <HomeCuration data={curatedMeetups1} />
    <HorizontalCardSection
      data={{
        destinationUrl: '/community',
        items: gatherings.slice(0, 20),
        name: 'gatherings',
        title: '멤버들만의 소모임',
      }}
    />
    {imminentSalonMeetups.items.length >= 4 && <HomeCuration data={imminentSalonMeetups} />}
    {soldOutSalonMeetups.items.length >= 4 && <HomeCuration data={soldOutSalonMeetups} />}
    <HomeCuration data={curatedMeetups2} />
    <HomeCuration data={curatedMeetups3} />
    <HomeCuration data={curatedMeetups4} />
    <HomeCuration data={curatedMeetups5} />
    <MainHostOpenCall />
    <HomeCuration data={curatedMeetups6} />
    <HomeCuration data={curatedMeetups9} />
    <HomeCuration data={curatedMeetups7} />
  </Flex>
);

const styles: StyleObject = {
  container: {
    alignItems: 'center',
    bg: 'white',
    flexDirection: 'column',
    mb: '14px',
    width: '100%',
  },
};
